.single{
  p{
    color: #374455;
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
  }
  .blog-page-way {
    a,span {
      margin-right: 10px;
    }
  }
.info-date {
  margin: 20px 0 30px 0;
}
  .social-links p {
    margin: 5px 0 90px;
    font-size: 11px;
  }
  .col-md-1 {
    padding-right: 0px;
  }
  .post-categories{
    ul{
      float: left;
      margin-bottom: 0px;
      padding-left: 0px;
    }
    li{
      list-style: none;
    }
  }
  @media (max-width: 768px) {
    .banner-blog .page-title {
      line-height: 45px;
    }
  }
  @media (max-width: 425px) {
  .banner-blog .page-title {
    margin-left: 10px;
    width: 400px;
    line-height: 1;
  }
    .col-md-4 {
      padding-left: 40px;
    }
    .blog-categories {
      margin: 51px 20px 40px;
    }
  }
  @media (max-width: 375px) {
    .banner-blog .page-title {
      margin-left: 0;
      width: 365px;
      font-size: 34px;
    }
  }
  @media (max-width: 320px) {
    .banner-blog .page-title {
      margin-left: 0;
      width: 318px;
      margin-top: 100px;
      line-height: 40px;

    }
    .social-links {
      position: inherit;
       padding-top: 0;
      ul {
        margin-top: -79px;
      }

    }
     .col-md-4 {
      padding-left: 80px;
    }
    .blog-categories{
      margin: 110px 20px 40px;
    }
    .info-date {
      margin: 20px 0 15px 0;
    }
    .page-title {
      margin-left: 0;
      width: 318px;
      font-weight: 700;
      text-align: center;
    }
  }
}

