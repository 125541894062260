.blog, .single {
  font-family: Oxygen;
  .banner-blog {
    background: url("../images/converted.png");
    width: 100%;
    height: 330px;
    &:before {
      background-color: #183d67;
      opacity: 0.7;
      content: "";
      position: absolute;
      width: 100%;
      height: 330px;
    }
    .page-title {
      height: 24px;
      color: @dropdown-bg;
      font-size: 44px;
      font-weight: 700;
      line-height: 55px;
      text-align: center;
      margin-top: 120px;
    }
    p {
      opacity: 0.75;
      color: #ffffff;
      font-size: 18px;
      line-height: 50px;
      text-align: center;
      margin-top: 25px;
    }

  }
  .col-md-1 {
    width: 5%;
    padding-left: 0px;
  }
  .col-md-4 {
    padding-left: 0px;
  }
  .light-gray {
    .blog-page-way {
      margin: 35px 0;
      color: #8d9193;
     a {
       font-size: 14px;
       color: #8d9193;
       text-decoration: none;
     }
      span{
        color:#43474a;
      }
    }

  }

  .post{
    padding-bottom: 60px;
    width: 740px;
    hr {
      border: 1px solid #e3e4ec;
      margin-bottom: 60px;
    }
    h2, a{
      color: #5c4977;
      font-size: 24px;
      font-weight: 700;
      margin-top: 30px;
      &:hover{
        text-decoration: none;
      }
    }
    .read-more-btn {
      color: #00c2f2;
      font-size: 11px;
      line-height: 3.5;
      text-transform: uppercase;
      padding-left: 30px;
    }
    .btn-more{
      width: 130px;
      height: 40px;
      border-radius: 100px;
      border: 1px solid #00c2f2;
      margin: 0 0 0 260px;
    }

}
  .info-date {
    opacity: 0.7;
    font-size: 14px;
    color: #374455;
    margin: 15px 0;
    span {
      color: #2692f5;
    }
  }
  .article-text{
    width: 720px;
    color: #374455;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
  }
  .social-links {

    color: #3b3d40;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
    line-height: 10px;
    text-transform: uppercase;
    margin-top: 40px;
    ul{
      padding-left: 0px;
    }
    p {
      margin: 10px 0 10px;
      font-family: Montserrat;
      font-weight: 400;
      line-height: 10px;
      text-transform: uppercase;
    }
    li {
      background: url("../images/blog/oval-2.png");
      width: 32px;
      height: 32px;
      list-style-type: none;
      float: left;
      margin-left: 8px;

    }
    img{
      padding: 8px 10px 8px 10px;
    }
    .facebook{
      padding: 8px 10px 8px 14px;
    }
  }
  .navigation{
    text-align: center;
  }
  .page-numbers{
    width: 33px;
    height: 33px;
    display: inline-block;
    border-radius: 4px;
    background-color: #edeef3;
    line-height: 2.4;
    color: #3b3d40;
    font-size: 14px;
    font-weight: 700;
    margin-left: 16px;
    &:hover{
      text-decoration: none;
    }
  &.current{
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    background-color: #2ba5f7;
  }
  }
  .next, .prev{
    color: #3b3d40;
    font-size: 14px;
    background-color: #fff;

  }
  .blog-categories{
    position: absolute;
    margin-top: 80px;
    width:325px;
    h4{
      color: #5c4977;
      font-size: 24px;
    }
    ul{
      padding-left: 0px;
    li {
      list-style-type: none;
    }
    }
    a,h3 {
      font-size: 16px;
      font-weight: 300;
      color: #374455;
      line-height: 40px;
      margin: 0;
      &:hover{
        color: #2692f5;
        text-decoration: none;
      }
    }
  }
}
.sheensay_fixed {
  position: fixed !important;
  top: 0 !important;

}

@media (max-width: 1024px) {
  .blog , .single {
    .blog-categories {
      margin-left: 50px;
    }
    .col-md-10 {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .col-md-4, .col-md-1 {
    display: inline-block;
  }
  .blog, .single {
    .blog-categories {
      position: inherit;
      margin: 40px 20px;
    }
    .social-links {
      height: 0px;
    }
    .post .btn-more {
      margin: 0 0 0 580px;
    }
  }
  .sheensay_fixed {
    position:  inherit !important;
    top: 0 !important;

  }
}

@media (max-width: 425px) {
  .blog-img{
  img{
    width: 400px!important;
  }
  }
  .blog, .single {
    .post {
      width: 400px;
    }
   .article-text {
      width: 400px;
    }
    .col-md-12 {
      width: 400px;
    }
    .post .btn-more {
      margin: 0 0 0 245px;
    }
    .banner-blog p {
      margin-left: 30px;
    }
    .banner-blog .page-title {
      margin-left: 30px;
    }
  }
}


@media (max-width: 375px) {
  .blog-img{
    img{
      width: 350px!important;
    }
  }
  .blog, .single {
    .post {
      width: 350px;
    }
    .article-text {
      width: 350px;
    }
    .col-md-1 {
      position: absolute;
    }
    .col-md-4 {
      padding-left: 40px;
    }
    .col-md-12 {
      width: 350px;
    }
    .post .btn-more {
      margin: 0 0 0 205px;
    }
  }
}

@media (max-width: 320px) {
  .blog, .single {
    .banner-blog {
      height: 305px;
      &:before{
        height: 305px;
      }
    }
    .banner-blog .page-title {
      font-size: 34px;
    }
  .banner-blog p {
    margin-left: 20px;
  }
    .light-gray .blog-page-way {
      margin: 35px 15px;
    }
   .post {
      width: 300px;
     text-align: center;
     padding: 0 0 0 15px;
     h2,a {
       margin-top: 15px;
     }
     .read-more-btn{
       padding-left: 5px;
     }
   }
     .info-date {
      margin: 20px 0 15px;
    }
     .social-links {
     padding-top: 30px;
     position: absolute;
    }
    .post .btn-more {
      margin: 0;
      width: 288px;

    }
    .col-md-6{
      padding: 0;
      margin-bottom: 100px;
    }
    .article-text {
      width: 288px;
      margin-bottom: 30px;
    }
    .col-md-4, .col-md-1 {
      display: block;
    }
    .col-md-1 {
      padding-left: 125px;
      position: initial;
    }
    .col-md-4 {
      padding-left: 75px;
    }
    .col-md-10 {
      width: 320px;
      padding: 10px 0 20px 0;
    }
    .col-md-12 {
      width: 320px;
      padding-top: 10px;
    }
   .blog-categories h4 {

      text-align: center;
    }
    footer{
      padding: 50px 0 0;
    }
  }
  .blog {
    .col-md-9 {
      padding-left: 0px;
    }
  }
  .blog-img{
    img{
  width: 288px!important;
  height: 240px;
    }
  }
  .article-text{
    p{
      width: 288px;
    }
  }
  .footer__copyright {
    width: 205px;
    margin-left: 60px;
  }


}
.top {
  .footer__menu {
    margin: 30px 0 0 0;
    a {
      color: #337ab7;
    }
  }
}